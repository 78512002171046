import React from "react";
import "../Inicio/Inicio.css";
import { SobreNosotros } from "../SobreNosotros/SobreNosotros";
import { CardCarousel } from "../CardsProducto/Contenedores/Container_Cards";
import { Tecnologic } from "../Tecnologic/Tecnologic";
import { Enviaremail } from "../Form/Form";
import { Navar } from "../Nav/Nav" ;
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../Utils/firebase";
export const Inicio = () => {

  const {categoria} = useParams();

  const [productos, setProductos] = useState([]);

  useEffect(()=>{
      const getData = async()=>{
          try {
              let queryRef = !categoria ? collection(db,"productos") :query(collection(db,"productos"),where("categoria","==",categoria));
              const response = await getDocs(queryRef);
              const datos = response.docs.map(doc=>{
                  const newDoc = {
                      ...doc.data(),
                      id:doc.id
                  }
                  return newDoc;
              });
              setProductos(datos)
          } catch (error) {
              console.log(error);
          }
      }
      getData();
      
  },[categoria])
  return (
    <> <Navar></Navar>
    <div className="inicio" id="Inicio">
      <div
        className="container_inicio"
        style={{
          backgroundImage:
            'url("https://firebasestorage.googleapis.com/v0/b/oak-solutions-5f57f.appspot.com/o/INICIO%20(2).png?alt=media&token=3f1b9b42-1f81-4be8-b427-f0940867e82a")',
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="text-center title_lema">
          <div class="web-container">
            <div class="web-content">
              <div class="web-content-container">
                <p class="web-content-text">OAK- </p>
                <ul class="web-content-list">
                  <li class="web-content-list-item">Solutions</li>
                  <li class="web-content-list-item">Software</li>
                  <li class="web-content-list-item">Marketing</li>
                  <li class="web-content-list-item">Developers</li>
                </ul>
              </div>
            </div>
          </div>{" "}
          <br></br>
          <br></br>

          <p  data-aos="zoom-in"  data-aos-easing="ease-out-cubic"  data-aos-duration="900"  className="desc-inicio"  >
            Transformando Desafíos en Oportunidades Digitales
          </p>
          <a data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="900" href="#NuestrosServicios" className="btn explora btn-dark"  >
            Explora Nuestros Servicios
          </a>
        </div>
      </div>
      <br id="sobre-nosotros"></br>
      <br></br>
      <br></br>
      <div >
        <SobreNosotros></SobreNosotros>
      </div>
      <br></br>
      <br></br>
       <Tecnologic></Tecnologic>
      <br id="NuestrosServicios"></br>
      <br></br>
      <div className="cards-container">
        <CardCarousel items={productos}></CardCarousel>
        
      </div>
      <span id="contactanos"></span>
      <div className="Form-container">
      
        <Enviaremail></Enviaremail>
      </div>
    </div> </>
  );
};
