import "./Container_Cards.css";
import { ItemList } from "../Cards_Servicios/Item_Cards";
import { useParams } from "react-router-dom";
import { db } from "../../../Utils/firebase";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";

export const CardCarousel = () => {
  const { categoria } = useParams();

  const [productos, setProductos] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        let queryRef = !categoria
          ? collection(db, "productos")
          : query(
              collection(db, "productos"),
              where("categoria", "==", categoria)
            );
        const response = await getDocs(queryRef);
        const datos = response.docs.map((doc) => {
          const newDoc = {
            ...doc.data(),
            id: doc.id,
          };
          return newDoc;
        });
        setProductos(datos);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [categoria]);
  return (
    <>
    <div className="container_nuestroServicios" >
      <br></br>
      <br></br>
        <h3 data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="900" >
          Nuestros servicos
        </h3>

          <br></br>
          <br></br>
          <ItemList items={productos} />
    </div>
    </>
  );
};
