import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer } from "./components/Footer/Footer";
import { Inicio } from "./components/Inicio/Inicio";

import Chatbot from "./components/Chat/Chat";
import { ItemDetailContainer } from "./components/CardsProducto/Contenedores/containerDetalle";
function App() {
// Scroll
  const [showScrollButton, setShowScrollButton] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", 
    });
  };
  return (
    <BrowserRouter>
      
      <div className="App">
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/item/:productId" element={<ItemDetailContainer />} />
        </Routes>
    
      </div>
     <div></div>

      <Chatbot> </Chatbot>
      {showScrollButton && (<button data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="900" className="scroll-to-top-button" onClick={scrollToTop}>↑</button>)}

      <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;