import { Item } from "./Cards"
import './Item_Cards.css';

export const ItemList = ({items})=>{
    console.log(items); // Agrega esta línea para depurar
    return(
        
        <div className="estilos-listado ">
              
            <div style={{width:"100%"}}></div>
            {
                items.map(producto=>(
                    <Item key={producto.id} item={producto}/>
                ))
            }
        </div>
      
        
    )
}

