import {
  SiLooker,
  SiReact,
  SiGoogledrive,
  SiGooglesheets,
  SiGoogleanalytics,
  SiGoogleappsscript,
  SiPython,
  SiDjango,
  SiJavascript
} from "react-icons/si";
import "../Tecnologic/Tecnologic.css";
import React from 'react';



export const Tecnologic = () => {

  return (
    <>
      <div className="container-tecnologic"  data-aos="zoom-in"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="900">
        <div className="container-text-tecnologic">
          <h2>Herramientas</h2>
          <h3>utilizadas</h3>
        </div>
        <div className="container-icons">
          <div className="iconts-w">
            <SiLooker></SiLooker>
          </div>
          <div className="iconts-w">
            <SiGoogledrive></SiGoogledrive>
          </div>
          <div className="iconts-w">
            <SiGooglesheets></SiGooglesheets>
          </div>
          <div className="iconts-w">
            <SiGoogleanalytics></SiGoogleanalytics>
          </div>
          <div className="iconts-w">
            <SiGoogleappsscript></SiGoogleappsscript>
          </div>
          <div className="iconts-w">
            <SiPython></SiPython>
          </div>
          <div className="iconts-w">
            <SiDjango></SiDjango>
          </div>
          <div className="iconts-w">
            <SiReact></SiReact>
          </div>
          <div className="iconts-w">
            <SiJavascript></SiJavascript>
          </div>
        </div>
      </div>
      
    </>
  );
};
