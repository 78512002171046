// Chatbot.js

import React, { useState, useEffect, useRef } from 'react';
import "./Chat.css";
import { HiX } from "react-icons/hi";
const Chatbot = () => {
  const messagesRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input] = useState('');
  const [selectedOptionName, setSelectedOptionName] = useState('');

  const options = [
    { key: '1', text: 'Nuestra agencia ofrece una amplia gama de servicios, incluyendo desarrollo de software a medida, diseño de interfaces, consultoría tecnológica y soluciones de transformación digital.' , name: 'Más Información'},
    { key: '2', text: 'Enfrentamos desafíos específicos mediante un enfoque colaborativo y utilizando las últimas tecnologías. Nuestro equipo está altamente especializado y sigue las mejores prácticas de la industria.', name: 'Desarrollo de Software'},
    { key: '3', text: 'La seguridad es una prioridad. Implementamos medidas avanzadas, como encriptación robusta, auditorías de seguridad y actualizaciones regulares para garantizar la protección de las soluciones digitales.', name: 'Seguridad Digital'},
    { key: '4', text: 'Colaboramos estrechamente con nuestros clientes desde la conceptualización hasta la implementación. Mantenemos una comunicación abierta, asegurándonos de comprender completamente las necesidades y expectativas de nuestros clientes.', name: 'Colaboración con Clientes'},
    { key: '5', text: 'Hemos trabajado en una variedad de proyectos, desde aplicaciones móviles innovadoras hasta sistemas complejos de gestión empresarial. Puedes explorar nuestros proyectos destacados en nuestra página web.', name: 'Proyectos Destacados'},
    { key: '6', text: 'Utilizamos tecnologías de vanguardia, como inteligencia artificial, desarrollo en la nube, blockchain y más. Adaptamos nuestras herramientas según las necesidades específicas de cada proyecto.', name: 'Tecnologías Utilizadas'},
    { key: '7', text: '¡Es genial que estés interesado! Puedes solicitar una consulta o iniciar un proyecto contactándonos a través de nuestro formulario en línea o llamando a nuestro equipo. Estamos ansiosos por trabajar contigo.', name: 'Consulta y Proyectos'},
  ];

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [messages]);

  const handleToggleChatbot = () => {
    setIsOpen(!isOpen);
  };

  const handleUserMessage = () => {
    const userMessage = { text: input, sender: 'user' };
    setMessages([...messages, userMessage]);

    handleChatbotResponse(input);
  };

  const handleChatbotResponse = (userInput) => {
    const selectedOption = options.find((option) => option.key === userInput);

    const botMessage = {
      text: selectedOption ? selectedOption.text : 'Lo siento, no entendí. ¿Puedes reformular tu pregunta o solicitud?',
      sender: 'bot',
    };

    setMessages([...messages, botMessage]);
  };

  const handleSelectOption = (optionKey) => {
    const selectedOption = options.find((option) => option.key === optionKey);

    const userMessage = { text: optionKey, sender: 'user' };
    setMessages([...messages, userMessage]);

    if (selectedOption) {
      setSelectedOptionName(selectedOption.name);
      handleChatbotResponse(optionKey);
    }
  };

  return (
    <div>
      <button onClick={handleToggleChatbot} className={`bubble-container ${isOpen ? 'open' : ''}`}>
        {isOpen ? 'Cerrar' : 'Preguntas frecuentes'}
      </button>
      {isOpen && (
        <div className="chatbox-container">
          <div className="chatbox-header">
            { 'Preguntas frecuentes'}
            <span  onClick={handleToggleChatbot} className="close-button- btn"><HiX></HiX></span>
          </div>
          <div className="chatbox-messages" ref={messagesRef} style={{ display: 'block' }}>
            {messages.length === 0 && (
              <div className=" placeholder-">
                <h4> Selecciona una opción </h4>
              </div>
            )}
            
            {messages.map((message, index) => (
              <div className='container-fondo-chat' key={index}>
              {selectedOptionName && (
              <div className="option-title">
                <h4>{selectedOptionName}</h4>
              </div>
            )}
                <div className={`${message.sender === 'user' ? 'user-message' : 'bot-message'} ${index % 2 === 0 ? 'even' : 'odd'}`}>
                  {message.text}
                  
                </div>
              </div>
            ))}
          </div>
          <div className="chatbox-options">
            {options.map((option) => (
              <p key={option.key} onClick={() => handleSelectOption(option.key)}>
                {option.name}
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
