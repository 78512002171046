import React, { useRef, useState } from "react";
import "../Form/Form.css";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

export const Enviaremail = () => {
  const refForm = useRef();
  const [formValid, setFormValid] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validar campos obligatorios aquí
    const username = refForm.current.username.value;
    const user_email = refForm.current.user_email.value;
    const message = refForm.current.message.value;

    if (!username || !user_email || !message) {
      // Mostrar alerta de Bootstrap para completar los datos
      setFormValid(false);
      return;
    }

    // Si los campos están completos, continuar con el envío del formulario
    const serviceId = process.env.REACT_APP_SERVICE_ID;
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    const apiKey = process.env.REACT_APP_API_KEY;
    
    emailjs
      .sendForm(serviceId, templateId, refForm.current, apiKey)
      .then(() => {
   
        mostrarAlerta("success", "¡Correo enviado con éxito!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(() => {
      
        mostrarAlerta("error", "Hubo un error al enviar el correo.");
      });
  };

  const mostrarAlerta = (icon, text) => {
    Swal.fire({
      icon: icon,
      title: text,
    });
  };

  // Función para cerrar la alerta de completar datos
  const cerrarAlerta = () => {
    setFormValid(true);
  };

  return (
    <>
      <form ref={refForm} action="" onSubmit={handleSubmit} >
        <div className="form-container">
          <div className="form">
            <span className="heading">Contactanos</span>
            <h2>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                <path   fill-rule="evenodd"   d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
              </svg> 
               Nombre
            </h2>
            <input placeholder="Ingresar" name="username" type="text" className="input"
            />
            <h2>
              <svg xmlns="http://www.w3.org/2000/svg"  width="30"  height="16"  fill="currentColor" class="bi bi-envelope-at" viewBox="0 0 16 16" >
                <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
              </svg>
              Email
            </h2>
            <input name="user_email" placeholder="Email" id="" type="email" className="input" />
            <h2>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16" >
              <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
              </svg>
              Comentarios
            </h2>
            <textarea placeholder="Ingresa tu consulta" rows="10" cols="30" id="" name="message" className="textarea"></textarea>
            <div className="button-container">
              {/* ALERTA*/}
              {!formValid && (
                <div className="alert alert-danger" role="alert">
                  Por favor, completa todos los campos.
                  <button className="close btn" onClick={cerrarAlerta}>
                    <span>&times;</span>
                  </button>
                </div>
              )}
              <div className="reset-button-container">
                <button id="reset-btn" className="reset-button">
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
