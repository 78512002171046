import { useState, useEffect } from "react";
import "./containerDetalle.css";
import { ItemDetail } from "../Detalle_Producto/DetalleProducto";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../Utils/firebase";

export const ItemDetailContainer = () => {
  const { productId } = useParams();
  const [item, setItem] = useState({});
  useEffect(() => {
    const queryRef = doc(db, "productos", productId);
    getDoc(queryRef)
      .then((response) => {
        const newDoc = {
          ...response.data(),
          id: response.id,
        };
        setItem(newDoc);
      })
      .catch((error) => console.log(error));
  }, [productId]);

  return (
    <div className="containerDetalle">
      <ItemDetail className="" item={item} />
    </div>
  );
};
