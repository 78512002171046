import React, { useState } from "react";
import "./SobreNosotros.css";

export const SobreNosotros = () => {

  const [mostrarTextoCompleto, setMostrarTextoCompleto] = useState(false);

  const handleMostrarTexto = () => {
    setMostrarTextoCompleto(!mostrarTextoCompleto);
  };

  return (
    <div className="Bienvenida" >
     
      <div data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="500" className="Bienvenida_descrip">
        <br></br>
        <br></br>
        <h2 >Sobre nosotros</h2>
        <br></br>
        <p>
          {mostrarTextoCompleto ? (
            <>
              <br></br> <br></br>
              En Oak Solutions, estamos dedicados a impulsar tu éxito en el
              mundo digital. Somos mucho más que una agencia de soluciones
              digitales; somos tu socio estratégico para lograr tus metas
              empresariales. Nuestro equipo está conformado por apasionados
              expertos en diseño web, desarrollo, análisis de datos y estrategia
              digital. <br></br> <br></br>Nos enorgullece ofrecer soluciones de
              vanguardia que te permitan destacarte en un mercado competitivo.
              Lo que nos diferencia es nuestro compromiso con resultados
              tangibles. No hacemos promesas vacías; en su lugar, ofrecemos
              soluciones basadas en datos que impulsan el crecimiento y el éxito
              de tu empresa.<br></br> <br></br> Nos esforzamos por comprender
              tus necesidades específicas y personalizar nuestras soluciones
              para cumplir con tus objetivos. Trabajamos mano a mano contigo
              para garantizar que tu presencia en línea sea impactante, tus
              operaciones sean eficientes y tu narrativa digital sea
              cautivadora. <br></br> <br></br>En Oak Solutions, creemos en el
              poder de la innovación y la colaboración. Únete a nosotros en este
              emocionante viaje hacia el éxito digital. Descubre cómo podemos
              ayudarte a transformar tus desafíos en oportunidades y a convertir
              tus datos en conocimiento estratégico. Tu éxito es nuestra razón
              de ser. Oak Solutions: Tu socio digital de confianza.
            </>
          ) : (
            // Mostrar solo un fragmento del texto
            <>
            En Oak Solutions, estamos dedicados a impulsar tu éxito en el
              mundo digital. Somos mucho más que una agencia de soluciones
              digitales; somos tu socio estratégico para lograr tus metas
              empresariales. Nuestro equipo está conformado por apasionados
              expertos en diseño web, desarrollo, análisis de datos y estrategia
              digital. <br></br> <br></br>Nos enorgullece ofrecer soluciones de
              vanguardia que te permitan destacarte en un mercado competitivo.
              Lo que nos diferencia es nuestro compromiso con resultados
              tangibles. No hacemos promesas vacías; en su lugar, ofrecemos
              soluciones basadas en datos que impulsan el crecimiento y el éxito
              de tu empresa.....{" "}
              <span onClick={handleMostrarTexto} className="leer-mas">
                Leer más
              </span>
            </>
          )}
        </p>
      </div>
    </div>
    
  );
};
