
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "../Nav/Nav.css"
export const Navar = () => {
  const [expanded, setExpanded] = useState(false);

  const closeNav = () => {
    setExpanded(false);
  };

  return (
    <Navbar  expand="lg"  className="nav fixed-top"  data-bs-theme="dark"  expanded={expanded}>
      <Container fluid className="nav">
        <Navbar.Toggle aria-controls="navbarScroll" onClick={() => setExpanded(!expanded)} />
        <img className="navbar-img" src="https://firebasestorage.googleapis.com/v0/b/oak-solutions-5f57f.appspot.com/o/Hoaj.png?alt=media&token=77d2b692-a33b-450e-87a7-75e0cd6b6f6c" alt="" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0 collapse-" style={{ maxHeight: '180px' }} navbarScroll>
            <Nav.Link href="#Inicio" onClick={closeNav}>
              Inicio
            </Nav.Link>
            <Nav.Link href="#sobre-nosotros" onClick={closeNav}>
              Sobre Nosotros
            </Nav.Link>
            <Nav.Link href="#NuestrosServicios" onClick={closeNav}>
              Nuestros Servicios
            </Nav.Link>
            <Nav.Link href="#contactanos" onClick={closeNav}>
              Contactanos
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
