import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Offcanvas } from 'react-bootstrap';
import './Cards.css';

export const Item = ({ item, onProductClick }) => {

  

  const [showDetails, setShowDetails] = useState(false);

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
    if (onProductClick) {
      onProductClick(item.id);
    }
  };

  return (
    <>
      <div className="">
        <figure className="snip1447" data-aos="fade-up">
          <img
            src={item.pictureUrl}
            data-aos="zoom-in"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="900"
            alt=""
          />
          <figcaption>
            <h2>{item.title}</h2>
            <p>{item.descCard}</p>
            <a 
            href="#contactanos"
              className="follow "
            >
              Contactar
            </a>
            <Link to="#" onClick={handleToggleDetails}>
              vermas
            </Link>
          </figcaption>
        </figure>
      </div>

      {/* Detalles del producto en offcanvas */}
      <div className=''> 
      <Offcanvas
  show={showDetails}
  onHide={() => setShowDetails(false)}
  placement="container"
  className={`custom-offcanvas centered-offcanvas`} 
  style={{ height: '45em' }} 
>
  <Offcanvas.Header closeButton >
  <samp className={'cerrar-detalle'}></samp>
  </Offcanvas.Header>

    <div className={`detalle-title`} > 
      <h2>{item.title}</h2>
    </div>

  <Offcanvas.Body>
  <div className={`detalle-desc-container`} > 
  <img
            src={item.pictureUrl1}
            data-aos="zoom-in"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="900"
            alt=""
          />
    <div className={`detalle-desc`} > 
      <p>{item.descDetalle}</p>
      </div>
    </div>
    <div className='contactanos-detalle'>
  <a
  href="#contactanos"
  className="btn-dark btn w-35"
  onClick={(e) => {
    e.preventDefault();
    setShowDetails(false); // Cierra el Offcanvas

    // Espera 300 milisegundos (ajustable según sea necesario) antes de redirigir
    setTimeout(() => {
      window.location.href = "#contactanos"; // Redirige a #contactanos
    }, 300);
  }}
>
  Contactar
</a></div>
  </Offcanvas.Body>
 

</Offcanvas>
</div>
    </>
  );
};
