import React, { useEffect, useState } from "react";
import "./DetalleProducto.css";
import { Container, Row, Col } from "reactstrap";
import Card from "react-bootstrap/Card";

export const ItemDetail = ({ item }) => {
  const [previewImg, setPreviewImg] = useState(item.pictureUrl);

  useEffect(() => {
    setPreviewImg(item.pictureUrl);
  }, [item]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [item]);

  const listItems = [
  
  ];

  return (
    <div>
      <div className="container-detalle-part1">
        <div className="container-detalle-part1-img">
          <img src={previewImg} alt="" className="w-50 imagen-detalle" />
        </div>

        <div className="container-detalle-part1-tilte-desc">
          <h2>{item.title}</h2>
          <p>{item.descCard}</p>
        </div>
      </div>
      <div className="container-detalle-cards-list">
        {listItems.map((listItem, index) => (
          <Card
            key={index}
            bg="dark"
            text="white"
            style={{ width: "18rem" }}
            className="mb-2"
          >
            <Card.Header>{listItem.title}</Card.Header>
            <Card.Body>
              <Card.Text>{listItem.content}</Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ItemDetail;
